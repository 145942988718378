import React from 'react';
import type { FC } from 'react';

import type { WithContentProps } from 'src/components/ContentProvider';
import { withContent } from 'src/components/ContentProvider';
import { PhoneField, CountryCodeDropdownField } from 'src/UI/Form';
import noop from 'src/utils/noop';
import { noopValidator } from 'src/utils/validation';
import type { OpaqueApiSetter } from 'src/hooks';

import type { ValueChangeEventHandler, ValidityChangeEventHandler } from 'src/UI/Form';
import type { Validator } from 'src/utils/validation';

import classes from './IntlPhoneField.module.scss';

const mapContentToProps = {
    countryCodeFieldLabel: 'LblPhoneCountryCode',
    countryCodeFieldSubLabel: 'SubLblPhoneCountryCode',
    phoneNumberFieldLabel: 'LblPhoneNum',
    phoneNumberFieldSubLabel: 'SubLblPhoneNum',
    phonePrefixTextLabel: 'LblPhonePrefix',
};

type PhoneValidator = Validator<string, string, { field: string; label: string; validationId?: string | undefined }>;

type IntlPhoneFieldContentProps = WithContentProps<typeof mapContentToProps>;

export interface IntlPhoneFieldProps extends IntlPhoneFieldContentProps {
    onPhoneNumberValueChange?: ValueChangeEventHandler;
    onCountryCodeValueChange?: ValueChangeEventHandler;
    onPhoneNumberValidityChange?: ValidityChangeEventHandler;
    onCountryCodeValidityChange?: ValidityChangeEventHandler;
    api?: OpaqueApiSetter;
    wasFormSubmitted?: boolean;
    emitIsoCountryCode?: boolean;
    enableInternationalNumbers?: boolean;
    validateCountryCode?: PhoneValidator;
    validatePhoneNumber?: PhoneValidator;
    defaultValues?: { countryCode: string; phoneNumber: string; isoCountryCode?: string };
    countryCodeValue: string;
}

const IntlPhoneField: FC<IntlPhoneFieldProps> = ({
    phoneNumberFieldLabel,
    phoneNumberFieldSubLabel,
    countryCodeFieldLabel,
    countryCodeFieldSubLabel,
    onPhoneNumberValueChange = noop,
    onCountryCodeValueChange = noop,
    onPhoneNumberValidityChange = noop as ValidityChangeEventHandler,
    validatePhoneNumber = noopValidator as PhoneValidator,
    api: setApi,
    wasFormSubmitted = false,
    emitIsoCountryCode = false,
    enableInternationalNumbers = true,
    defaultValues,
    countryCodeValue,
}) => (
    <div className={`is-flex ${classes.phoneNumberContainer}`}>
        <CountryCodeDropdownField
            name="countryCode"
            label={countryCodeFieldLabel}
            subLabel={countryCodeFieldSubLabel}
            onValueChange={onCountryCodeValueChange}
            value={countryCodeValue}
            emitIsoCode={emitIsoCountryCode}
            enableInternationalNumbers={enableInternationalNumbers}
        />
        <PhoneField
            name="phoneNumber"
            label={phoneNumberFieldLabel}
            subLabel={phoneNumberFieldSubLabel}
            onValueChange={onPhoneNumberValueChange}
            onValidityChange={onPhoneNumberValidityChange}
            required
            wasFormSubmitted={wasFormSubmitted}
            validate={validatePhoneNumber}
            api={setApi}
            countryCode={defaultValues?.countryCode}
            defaultValue={defaultValues?.phoneNumber}
        />
    </div>
);

export { IntlPhoneField };
export default withContent(mapContentToProps)(IntlPhoneField);
