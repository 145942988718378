import React, { forwardRef } from 'react';
import classNames from 'classnames';

import type { ButtonHTMLAttributes, MouseEventHandler } from 'react';

import { RdsButton, RdsIcon } from '../RdsComponents';
import classes from './Button.module.scss';

const linkVariants = {
    'link-external-footer': '',
    'link-external-inline': '',
    'link-footer': '',
    'link-heading': '',
    'link-icon-left': '',
    'link-inline': '',
    'link-standalone': '',
};
const linkVariantsWithIcons = {
    'link-external-footer': '',
    'link-external-inline': '',
    'link-standalone': '',
    'link-icon-left': '',
};
type LinkVariant = keyof typeof linkVariants;
const isLinkVariant = (str: string): str is LinkVariant => Object.prototype.hasOwnProperty.call(linkVariants, str);

type LinkVariantWithIcon = keyof typeof linkVariantsWithIcons;
const isLinkVariantWithIcon = (str: string): str is LinkVariantWithIcon =>
    Object.prototype.hasOwnProperty.call(linkVariantsWithIcons, str);

const rdsButtonVariants = {
    primary: '',
    'primary-alt': '',
    secondary: '',
    tertiary: '',
};
export type RdsButtonVariant = keyof typeof rdsButtonVariants;

const visualVariants = {
    graphic: '',
    icon: '',
};
type VisualVariant = keyof typeof visualVariants;
const isVisualVariant = (str: string): str is VisualVariant =>
    Object.prototype.hasOwnProperty.call(visualVariants, str);

const linkVariantSizePropsToIconSuffixes = {
    'body-2': 'lg',
    'body-3': 'md',
    sm: 'sm',
};
type LinkVariantIconSize = keyof typeof linkVariantSizePropsToIconSuffixes;

interface BaseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: RdsButtonVariant | LinkVariant | VisualVariant;
    type?: 'button' | 'submit' | 'reset';
    size?: 'small';
    // NOTE almost every button fills the width of the container so this is a sane default. Use
    // `width={null}` to get a button with width that conforms to the text.
    width?: 'full' | null;
    linkVariantSize?: LinkVariantIconSize;
    linkVariantLeftIconName?: string;
    linkVariantLeftIconSize?: '16';
    id?: string;
    className?: string;
    clicked?: MouseEventHandler;
    disabled?: boolean;
}

interface ButtonTypeButtonProps extends BaseButtonProps {
    type?: 'button';
    clicked: MouseEventHandler;
}

interface OtherTypeButtonProps extends BaseButtonProps {
    type: 'submit' | 'reset';
}

export type ButtonProps = ButtonTypeButtonProps | OtherTypeButtonProps;

const iconEnv = process.env.NODE_ENV === 'production' ? 'prod' : 'dev';

const getLinkVariantClassNames = (variant: LinkVariant, size?: LinkVariantIconSize): string => {
    let variantClassNameSuffix = variant.replace('link-', '');
    if (variant.includes('external')) {
        variantClassNameSuffix = variant.replace('external', 'ext').replace('link-', '');
    }
    if (variant.includes('standalone')) {
        variantClassNameSuffix = 'sa';
    }
    const hasIcon = isLinkVariantWithIcon(variant);

    return classNames(`rds-link-${variantClassNameSuffix}`, hasIcon && size ? `is-rds-${size}` : null);
};

const getRightIconForLinkVariant = (variant: LinkVariant, size?: LinkVariantIconSize) => {
    let iconName = null;
    if (variant === 'link-external-inline' || variant === 'link-external-footer') {
        iconName = `system-link-out-${size ? linkVariantSizePropsToIconSuffixes[size] : 'md'}`;
    } else if (variant === 'link-standalone') {
        iconName = `system-arrow-${size ? linkVariantSizePropsToIconSuffixes[size] : 'md'}`;
    }

    return iconName ? <RdsIcon env={iconEnv} name={iconName} size="16" /> : null;
};

const getLeftIconForLinkVariant = (
    variant: LinkVariant,
    size?: LinkVariantIconSize,
    leftIconName?: string,
    leftIconSize?: '16'
) => {
    let iconName = null;
    if (variant === 'link-icon-left') {
        iconName = leftIconName;
    }

    return iconName ? <RdsIcon env={iconEnv} name={iconName} size={leftIconSize} /> : null;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            children,
            variant = 'primary',
            type = 'button',
            size,
            width = 'full',
            linkVariantSize,
            linkVariantLeftIconName,
            linkVariantLeftIconSize,
            id,
            clicked,
            disabled = false,
            className,
            ...props
        },
        ref
    ) => {
        const commonProps = { ...props, type, id, onClick: clicked, disabled, ref };

        // NOTE Disabling since this requires a string literal for the `type` prop and we're allowing it to be dynamic
        /* eslint-disable react/button-has-type */
        if (isLinkVariant(variant)) {
            return (
                <button
                    {...commonProps}
                    className={classNames(
                        classes.reset,
                        className,
                        getLinkVariantClassNames(variant, linkVariantSize),
                        { 'rds-link-disabled': disabled }
                    )}
                >
                    {getLeftIconForLinkVariant(
                        variant,
                        linkVariantSize,
                        linkVariantLeftIconName,
                        linkVariantLeftIconSize
                    )}
                    {children}
                    {getRightIconForLinkVariant(variant, linkVariantSize)}
                </button>
            );
        }

        if (isVisualVariant(variant)) {
            return (
                <button
                    {...commonProps}
                    className={classNames(classes.reset, className, {
                        [classes.icon]: variant === 'icon',
                    })}
                >
                    {children}
                </button>
            );
        }

        return (
            <RdsButton
                {...commonProps}
                className={className}
                size={size}
                variant={variant}
                width={width || undefined}
                colorBranded={false}
            >
                {children}
            </RdsButton>
        );
    }
);

export default Button;
